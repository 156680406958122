/* import __COLOCATED_TEMPLATE__ from './picker.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { type AwayStatusReason as InboxAwayStatusReason } from 'embercom/objects/inbox/away-reason';
import type AwayStatusReason from 'embercom/models/away-status-reason';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
type PickerStatus = 'active' | 'away' | 'reassign';

interface Args {
  currentStatus: PickerStatus;
  currentAwayStatusReason: AwayStatusReason | InboxAwayStatusReason;
  awayStatusReasons: AwayStatusReason[] | InboxAwayStatusReason[];
  onSetAwayStatus: (
    status: AwayStatusReason | InboxAwayStatusReason | null,
    isReassigning?: boolean,
  ) => void;
  onSetActive: () => void;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class CustomAwayStatusPicker extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;

  get currentStatusBackgroundColor() {
    if (this.args.currentStatus === 'active') {
      return 'bg-success-container success-fill hover:border-success-border';
    }

    if (this.args.currentStatus === 'reassign') {
      return 'bg-neutral-container text-muted hover:border-neutral-border-emphasis';
    }

    return 'bg-notice-container text-default hover:border-notice-border-emphasis';
  }

  @action setAway(status: AwayStatusReason | InboxAwayStatusReason | null = null) {
    this.args.onSetAwayStatus(status);
  }

  @action setAwayAndReassigning(status: AwayStatusReason | InboxAwayStatusReason | null = null) {
    this.args.onSetAwayStatus(status, true);
  }

  @action onTopMenuClick(status: PickerStatus) {
    if (status === 'active') {
      this.args.onSetActive();
    }
  }

  get groupList() {
    return [
      {
        items: [
          {
            text: this.intl.t('components.common.custom-away-status-picker.active'),
            value: 'active',
            component: 'common/custom-away-status-picker/status-indicator',
            isAway: false,
            isReassigning: false,
            isSelected: this.args.currentStatus === 'active',
          },
          {
            text: this.intl.t('components.common.custom-away-status-picker.away'),
            value: 'away',
            component: 'common/custom-away-status-picker/reasons-list',
            currentStatus: this.args.currentStatus,
            currentAwayStatusReason: this.args.currentAwayStatusReason,
            awayStatusReasons: this.args.awayStatusReasons,
            onSelect: this.setAway,
            isReassigning: false,
          },
          {
            text: this.intl.t('components.common.custom-away-status-picker.reassign'),
            value: 'reassign',
            component: 'common/custom-away-status-picker/reasons-list',
            currentStatus: this.args.currentStatus,
            currentAwayStatusReason: this.args.currentAwayStatusReason,
            awayStatusReasons: this.args.awayStatusReasons,
            onSelect: this.setAwayAndReassigning,
            isReassigning: true,
          },
        ],
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::CustomAwayStatusPicker::Picker': typeof CustomAwayStatusPicker;
    'common/custom-away-status-picker/picker': typeof CustomAwayStatusPicker;
  }
}
