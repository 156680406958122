/* import __COLOCATED_TEMPLATE__ from './status-indicator.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';

interface Signature {
  Args: {
    item: {
      isAway: boolean;
      isReassigning: boolean;
      text?: string;
    };
  };
}

export default class StatusIndicator extends Component<Signature> {
  get wrapperBackgroundColor() {
    if (this.args.item.isAway) {
      if (this.args.item.isReassigning) {
        return 'bg-neutral-container';
      }

      return 'bg-notice-container';
    }

    return 'bg-success-container';
  }

  get iconColor() {
    if (this.args.item.isAway) {
      if (this.args.item.isReassigning) {
        return 'neutral-fill-emphasis';
      }

      return 'notice-fill-emphasis';
    }

    return 'success-fill-emphasis';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::CustomAwayStatusPicker::StatusIndicator': typeof StatusIndicator;
    'common/custom-away-status-picker/status-indicator': typeof StatusIndicator;
  }
}
