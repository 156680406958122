/* import __COLOCATED_TEMPLATE__ from './reasons-list.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { type AwayStatusReason as InboxAwayStatusReason } from 'embercom/objects/inbox/away-reason';
import type AwayStatusReason from 'embercom/models/away-status-reason';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';

interface Args {
  item: {
    text: string;
    currentStatus: string;
    currentAwayStatusReason: AwayStatusReason | InboxAwayStatusReason;
    awayStatusReasons: AwayStatusReason[] | InboxAwayStatusReason[];
    onSelect: (status: AwayStatusReason | InboxAwayStatusReason) => void;
    isReassigning?: boolean;
  };
  hideDropdownOverlay: () => void;
}

export default class CustomAwayStatusPickerReasonsList extends Component<Args> {
  @service declare session: Session;

  @action setAwayReason(status: AwayStatusReason | InboxAwayStatusReason) {
    this.args.item.onSelect(status);
    this.args.hideDropdownOverlay();
  }

  @tracked searchQuery = '';

  get awayReasons() {
    return this.args.item.awayStatusReasons;
  }

  get isStatusMandatory() {
    return this.session.workspace.adminAwayReasonIsMandatory;
  }

  get statusIndicatorArgs() {
    return {
      isAway: true,
      isReassigning: this.args.item.isReassigning || false,
      text: this.args.item.text,
    };
  }

  get filteredAwayReasons() {
    return this.awayReasons.filter((reason) => {
      return (
        reason.label.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        reason.id === this.currentSelectedReasonId
      );
    });
  }

  get isAway() {
    return this.args.item.currentStatus !== 'active';
  }

  get searchFieldVisible() {
    return this.awayReasons.length > 10;
  }

  get currentSelectedReasonId() {
    return this.args.item.currentAwayStatusReason?.id;
  }

  matchPopoverDimensionsToMenuPopover(popperModifierData: any) {
    let { reference: referenceElement, popper: popperElement } = popperModifierData.state.elements;
    let referenceElementPopoverContainer = referenceElement.closest('[data-popover-content]');
    popperElement.style.width = `${referenceElementPopoverContainer.offsetWidth}px`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::CustomAwayStatusPicker::ReasonsList': typeof CustomAwayStatusPickerReasonsList;
    'common/custom-away-status-picker/reasons-list': typeof CustomAwayStatusPickerReasonsList;
  }
}
